<template>
  <div>
    <div class="image-box">
      <van-image class="image" :src="image" fit="contain" />
    </div>
    <van-tabs
      swipeable
      class="main_tabs"
      title-active-color="#F67F58"
      title-inactive-color="#999999"
      line-height="2"
      @click="onClick"
    >
      <!-- rentType 房源租赁类型（1-整租；2-合租；3-学生公寓） -->
      <van-tab v-for="item in pageData" :key="item.name" :title="item.name">
        <!-- 没有子tab，显示文字内容 -->
        <div v-if="item.rentType != 3" class="content-container">
          <!-- <van-image class="image" :src="item.image" fit="contain" /> -->
          <!-- <div class="title">{{ item.title }}</div> -->
          <p class="sub-title">
            {{ item.content }}
          </p>
        </div>
        <!-- 有子tab -->
        <van-tabs
          v-else
          swipeable
          class="sub-tabs"
          title-active-color="#F67F58"
          title-inactive-color="#999999"
          line-width="0.01"
        >
          <van-tab
            v-for="subItem in item.subRoomType"
            :key="subItem.title"
            :title="subItem.title"
          >
            <div class="content-container">
              <p class="sub-title">
                {{ subItem.content }}
              </p>
              <!-- <van-image class="image" :src="item.image" fit="contain" /> -->
            </div>
          </van-tab>
        </van-tabs>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getRoomintroduceInfo, getServicesExplainInfo } from "@/api/api";

export default {
  name: "RoomTypeIntroduce",
  data() {
    return {
      loading: true,
      pageData: {},
      image: "",
    };
  },
  created() {
    //请求页面数据
    let houseId = this.$route.query.houseId; //房源id
    let serviceKeys = this.$route.query.serviceKey; // 服务id
    console.log("houseId:" + houseId + "serviceKeys:" + serviceKeys);
    if (houseId) {
      //房间介绍
      getRoomintroduceInfo({
        houseId: houseId,
      }).then((res) => {
        this.pageData = res.data;
        this.image = res.data[0].image;
        console.log("房间介绍===res.data结果===>", res.data);
        this.loading = false;
      });
    } else if (serviceKeys) {
      //服务说明
      getServicesExplainInfo({
        serviceKeys: serviceKeys,
      }).then((res) => {
        console.log("服务说明===res.data结果===>", res.data);
        this.pageData = res.data;
        this.loading = false;
      });
    } else {
      this.$failToast("参数错误");
      return;
    }
  },
  mounted() {},
  methods: {
    onClick(e) {
      console.log("点击了标题结果===>", e);
      this.image = this.pageData[e].image;
    },
  },
};
</script>

<style lang="scss" scoped>
// https://blog.csdn.net/weixin_40442888/article/details/97622810
/deep/ .main_tabs .van-tabs__nav--line {
  height: 35px;
}

.main_tabs {
  top: 0px;
}

.content-container {
  padding: 10px;
  background-color: #fff !important;

}
.image-box {
  padding: 30px 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
}

.title {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
}

.sub-title {
  color: #666666;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400;
}

.image {
  width: 300px;
  height: 300px;
}

/deep/ .sub-tabs .van-tab {
  font-size: 12.5px;
}

/deep/ .van-tabs__line {
  background-color: #f67f58;
}
</style>

<style lang="scss">
 html {
  background-color: #fff !important;
}
</style>